import { CSSProperties, StyleSheet } from 'aphrodite';
import {
	background as backgroundColor,
	brandPrimaryHover,
	brandSecondary,
	destructive,
	error,
	grayIconFill,
	mention,
	success,
	successTint,
} from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const errorBackgroundColor = error;
export const contentErrorBorderColor = error;
export const contentWhiteBackgroundColor = '#fff';
export const contentWhiteBorderColor = '#f3f3f3';
export const contentBlueBackgroundColor = mention;
export const contentBlueBorderColor = '#D3DDBE';
export const contentLightBlueBorderColor = brandSecondary;
export const contentEmailGuideBackgroundColor = brandPrimaryHover;
export const contentEmailGuideBorderColor = 'transparent';

const popoverGenerator = (
	background: string,
	borderRadius: string | number,
	boxShadow: string,
	borderWidth: string | number,
	borderColor: string
): CSSProperties => {
	const result: CSSProperties = {
		background,
		border: `${typeof borderWidth === 'number' ? `${borderWidth}px` : borderWidth} solid ${borderColor};`,
		borderRadius,
		boxShadow,
	};

	return result;
};

export const styleSheet = StyleSheet.create({
	anchorContainer: {},
	arrow: {
		margin: -1,
	},
	hide: {
		...CssSelectors.allChildren({
			opacity: 0,
		}),
	},
	popoverContentBackground: {
		...popoverGenerator(backgroundColor, 3, '', 1, backgroundColor),
		border: `1px solid ${grayIconFill}`,
		borderRadius: 3,
	},
	popoverContentBlue: {
		...popoverGenerator(
			contentBlueBackgroundColor,
			3,
			'0px 0px 6px rgba(139, 139, 139, 0.1)',
			1,
			contentBlueBorderColor
		),
	},
	popoverContentEmailGuide: {
		...popoverGenerator(
			contentEmailGuideBackgroundColor,
			20,
			'0 1px 2px 0 rgba(162,162,162,0.50)',
			0,
			contentEmailGuideBorderColor
		),
	},
	popoverContentError: {
		...popoverGenerator(errorBackgroundColor, 3, '0 1px 2px 0 rgba(162,162,162,0.50)', 0, contentErrorBorderColor),
		...CssSelectors.allDescendants(
			{
				color: '#fff',
				fontSize: 14,
				fontWeight: 600,
				padding: '18px 30px',
			},
			'.tinyPopover-content'
		),
	},
	popoverContentErrorSecondary: {
		...popoverGenerator('#fef3f5', 3, '', 0, destructive),
		border: `1px solid ${contentErrorBorderColor}`,
		borderRadius: 3,
		...CssSelectors.allDescendants(
			{
				color: '#fff',
				fontSize: 14,
				fontWeight: 600,
				padding: '18px 30px',
			},
			'.tinyPopover-content'
		),
	},
	popoverContentLightBlue: {
		...popoverGenerator(
			contentBlueBackgroundColor,
			3,
			'0px 0px 6px rgba(139, 139, 139, 0.1)',
			1,
			contentLightBlueBorderColor
		),
		marginLeft: 4,
		width: 270,
	},
	popoverContentPlugin: {
		width: 315,
	},
	popoverContentSuccess: {
		...popoverGenerator(successTint, 3, '0px 0px 6px rgba(139, 139, 139, 0.1)', 1, success),
	},
	popoverContentWhite: {
		...popoverGenerator(
			contentWhiteBackgroundColor,
			3,
			'0 1px 2px 0 rgba(162,162,162,0.50)',
			1,
			contentWhiteBorderColor
		),
	},
	tinyPopover: {
		margin: -1,
		opacity: 1,
		transition: 'opacity 0.1s',
		zIndex: 10,
	},
});
