import moment from 'moment';
import * as React from 'react';
import { default as ReactDayPicker } from 'react-day-picker';
import { Modifier, Modifiers } from 'react-day-picker/types/common';
import {
	CaptionElementProps,
	DayPickerProps,
	NavbarElementProps,
	WeekdayElementProps,
} from 'react-day-picker/types/props';
import { IEnvironment } from '../../../models/Environment';
import { DayPickerNavbarIcon } from '../svgs/icons/DayPickerNavbarIcon';
import './styles.less';

/** Weekday column header component */
export const Weekday: React.FC<WeekdayElementProps & { onRenderName?(name: string): string }> = props => {
	const { className, localeUtils, weekday, locale, onRenderName } = props;

	let name = localeUtils?.formatWeekdayLong(weekday, locale);
	name = onRenderName ? onRenderName(name) : name.slice(0, 3);
	return (
		<div className={className} title={name}>
			{name}
		</div>
	);
};

/** Empty caption header */
const Caption: React.FC<CaptionElementProps> = () => {
	return null;
};

interface INavBarNavigationHooks {
	onWillChangeToNextMonth?(e: React.MouseEvent<HTMLElement>, currentlyDisplayedMonth: Date, nextMonth?: Date): void;
	onWillChangeToPreviousMonth?(e: React.MouseEvent<HTMLElement>, currentlyDisplayedMonth: Date, prevMonth?: Date): void;
	showNextButton?: boolean;
	showPreviousButton?: boolean;
}

/** Nav bar with month name */
const NavBar: React.FC<IProps & NavbarElementProps & Partial<INavBarNavigationHooks>> = props => {
	const monthMoment = props.previousMonth
		? moment(props.previousMonth).add(1, 'month')
		: moment(props.nextMonth).subtract(1, 'month');
	const monthName = props.localeUtils.getMonths(props.locale)[monthMoment.month()];
	const onPrevMonthClicked = (e: React.MouseEvent<HTMLElement>) => {
		props.onWillChangeToPreviousMonth?.(e, props.previousMonth);
		e.stopPropagation();
		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();
		props.onPreviousClick();
	};
	const onNextMonthClicked = (e: React.MouseEvent<HTMLElement>) => {
		props.onWillChangeToNextMonth?.(e, props.nextMonth);
		e.stopPropagation();
		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();
		props.onNextClick();
	};
	return (
		<div className={props.className}>
			<button
				onClick={onPrevMonthClicked}
				className={`DayPicker-NavBar-prev-button${!props.showPreviousButton ? ' hide' : ''}`}
			>
				<DayPickerNavbarIcon />
			</button>
			<span className='truncate-text DayPicker-NavBar-month'>{`${monthName}, ${monthMoment.year()}`}</span>
			<button
				onClick={onNextMonthClicked}
				className={`DayPicker-NavBar-next-button${!props.showNextButton ? ' hide' : ''}`}
			>
				<DayPickerNavbarIcon />
			</button>
		</div>
	);
};

const NavBarWithNavigationHooks = (hooks?: INavBarNavigationHooks): React.FC<NavbarElementProps> =>
	function NavBarWithNavHooks(props) {
		return <NavBar {...props} {...hooks} />;
	};

interface IProps extends DayPickerProps, INavBarNavigationHooks {
	allowPastDates?: boolean;
	canChangeToNextMonth?: boolean;
	canChangeToPreviousMonth?: boolean;
	className?: string;
	defaultSelectedDay?: Date;
	disabledDays?: Modifier[];
	environment?: IEnvironment;
	minDate?: Date;
	maxDate?: Date;
	modifiers?: Partial<Modifiers>;
	modifiersStyles?: object;
	onRenderDay?(
		day: Date,
		modifiers: Partial<Modifiers> & { selected?: boolean; disabled?: boolean },
		props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
	): React.ReactNode;
	showOverflowDates?: boolean; // If true 6 weeks are shown in calendar as opposed to 4.
}

/** Day picker calendar */
export const DayPicker: React.FC<IProps> = props => {
	const today = new Date();
	const {
		allowPastDates,
		className,
		defaultSelectedDay,
		disabledDays = [],
		environment,
		selectedDays,
		showOverflowDates = true,
		minDate,
		maxDate,
		...restProps
	} = props;
	const selectedDay = selectedDays || defaultSelectedDay;
	const constraintClass = environment?.appType === 'plugin' ? 'day-picker-plugin' : '';
	const NavBarWithHooks = NavBarWithNavigationHooks({
		onWillChangeToNextMonth: props.onWillChangeToNextMonth,
		onWillChangeToPreviousMonth: props.onWillChangeToPreviousMonth,
		showNextButton: props.canChangeToNextMonth ?? true,
		showPreviousButton: props.canChangeToPreviousMonth ?? true,
	});

	/**
	 * Retrieves the start of the selected day(s) since can be a date or range of dates
	 *
	 * @param {Modifier | Modifier[]} d - The selected day(s)
	 * @returns {Date} - The date object of the start of the selected day(s).
	 */
	const getStartDate = (d: Modifier | Modifier[]): Date => {
		const day = Array.isArray(d) ? d[0] : d;
		let month = new Date();

		if (day) {
			if (day instanceof Date) {
				month = day as Date;
			} else if ('from' in day) {
				// testing for instance of RangeModifier
				month = day.from;
			} else if ('before' in day) {
				// testing for instance of BeforeModifier or BeforeAfterModifier
				month = day.before;
			} else if ('after' in day) {
				// testing for instance of AfterModifier
				month = day.after;
			}
		}

		return new Date(month);
	};

	const onRenderDay = (day: Date, modifiers: any) => {
		const mods: {
			outside?: boolean;
			selected?: boolean;
			today?: boolean;
			disabled?: boolean;
		} = modifiers;
		const selectedClassName = mods.selected ? 'day-picker-day--selected' : '';
		const todayClassName = mods.today ? 'day-picker-day--today' : '';
		const outsideClassName = mods.today ? 'day-picker-day--outside' : '';
		const disabledClassName = mods.disabled ? 'day-picker-day--disabled' : '';
		const spanProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> = {
			className: 'day-picker-day',
		};
		const el = props.onRenderDay?.(day, modifiers, spanProps);
		if (el !== undefined && el !== null) {
			return el;
		}

		return (
			<span
				className={`${spanProps.className} ${selectedClassName} ${todayClassName} ${outsideClassName} ${disabledClassName}`}
			>
				{day.getDate()}
			</span>
		);
	};

	return (
		<div className={`day-picker ${constraintClass} ${className || ''}`}>
			<ReactDayPicker
				{...restProps}
				captionElement={Caption}
				disabledDays={[!allowPastDates && { after: maxDate || undefined, before: minDate ?? today }, ...disabledDays]}
				fixedWeeks={showOverflowDates}
				navbarElement={restProps.navbarElement ?? NavBarWithHooks}
				renderDay={onRenderDay}
				selectedDays={selectedDay}
				showOutsideDays={showOverflowDates}
				weekdayElement={restProps.weekdayElement ?? Weekday}
				initialMonth={getStartDate(selectedDay)}
			/>
		</div>
	);
};
