import { grayIconFill } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	className?: string;
	fillColor?: string;
	height?: number;
	width?: number;
}

export const ClockIcon: React.FC<IProps> = props => {
	const { className, fillColor, width = 17, height = 18, ...restProps } = props;
	return (
		<SvgIcon
			{...restProps}
			className={`clock-icon ${className || ''}`}
			height={height}
			width={width}
			viewBox='0 0 17 18'
		>
			<g fill={fillColor || grayIconFill} fillRule='evenodd'>
				<path d='M9 2.68421053C5.526 2.68421053 2.7 5.49368421 2.7 8.94736842 2.7 12.4010526 5.526 15.2105263 9 15.2105263 12.474 15.2105263 15.3 12.4010526 15.3 8.94736842 15.3 5.49368421 12.474 2.68421053 9 2.68421053M9 17C4.5342 17 .9 13.3879474.9 8.94736842.9 4.50678947 4.5342.894736842 9 .894736842 13.4658.894736842 17.1 4.50678947 17.1 8.94736842 17.1 13.3879474 13.4658 17 9 17M17.2417615 4.47368421C17.709592 3.99902974 18 3.34977963 18 2.63209504 18 1.17830121 16.8154178 0 15.3519027 0 14.6307375 0 13.9775402.28865309 13.5.755411278L17.2417615 4.47368421zM.758238525 4.47368421C.290408003 3.99902974 0 3.34977963 0 2.63209504 0 1.17830121 1.18458219 0 2.64809729 0 3.36926246 0 4.02245979.28865309 4.5.755411278L.758238525 4.47368421z' />
				<path d='M11.2481836,12.5263158 L8.38011472,9.75177867 C8.20038241,9.57790767 8.1,9.34299686 8.1,9.09791275 L8.1,4.47368421 L10.0120459,4.47368421 L10.0120459,8.71502663 L12.6,11.218584 L11.2481836,12.5263158 Z' />
			</g>
		</SvgIcon>
	);
};
