import { grayIconFill } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	opacity?: number;
	width?: number;
	height?: number;
}

export const CalendarDateIcon: React.FC<IProps> = props => {
	const { className, fillColor, opacity, width = 16, height = 16 } = props;
	return (
		<SvgIcon className={`calendar-date-icon ${className || ''}`} width={width} height={height} viewBox='0 0 16 16'>
			<path
				fill={fillColor || grayIconFill}
				fillRule='evenodd'
				d='M14.3977,14.4 L1.6001,14.4 L1.6001,5.6 L14.3993,5.6 L14.3977,14.4 Z M14.4001,2.4 L13.6001,2.4 L13.6001,0 L12.0001,0 L12.0001,4 L11.2001,4 L11.2001,2.4 L5.6001,2.4 L5.6001,0 L4.0001,0 L4.0001,4 L3.2001,4 L3.2001,2.4 L1.6001,2.4 C0.7177,2.4 0.0001,3.1184 0.0001,4 L0.0001,14.4 C0.0001,15.2824 0.7177,16 1.6001,16 L14.4001,16 C15.2825,16 16.0001,15.2824 16.0001,14.4 L16.0001,4 C16.0001,3.1184 15.2825,2.4 14.4001,2.4 L14.4001,2.4 Z'
				opacity={opacity || '1'}
			/>
		</SvgIcon>
	);
};
