import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const DayPickerNavbarIcon: React.FC<IProps> = props => {
	const { className } = props;

	return (
		<SvgIcon className={className} height={14} width={9}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-663.000000, -575.000000)'>
				<g transform='translate(641.000000, 497.000000)' fill='#00AAE8'>
					<polygon points='28.707 78 22 84.707 28.707 91.414 30.121 90 24.828 84.707 30.121 79.414' />
				</g>
			</g>
		</SvgIcon>
	);
};
